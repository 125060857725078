.ant-layout {
  height: 100% !important;
}

.ant-layout-footer {
  padding: 0px 0 20px 0 !important;
}

.ant-card + .ant-card {
  margin-top: 10px;
}

.ant-layout-content {
  min-height: auto !important;
  background-color: #eff2f5 !important;
}

.ant-breadcrumb {
  margin-top: 20px !important;
  padding: 0 20px !important;
}

.ant-layout-sider {
  background-color: white !important;
}

.ant-layout-sider-children {
  width: 200px;
  position: fixed;
}

.ant-layout-sider-collapsed {
  .ant-layout-sider-children {
    width: 80px !important;
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.ant-typography {
  margin-bottom: 0px !important;
}

.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #9e9e9e !important;
}

.custom-loading {
  text-align: center;

  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
