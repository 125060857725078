.pdf {
  width: 20cm;
  height: auto;
  min-height: 29.7cm;
  padding: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 6px #0000006e;
  margin-bottom: 5px;

  .html2pdf__page-break {
    margin: 20px;
  }

  h1,
  h2 {
    font-weight: bold;
  }

  label {
    color: #858585;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .primary-color {
    color: #00b6fe;
  }

  .header {
    display: flex;
    flex-direction: column;

    h1 {
      color: #00b6fe;
      margin-top: 6px;
    }
  }

  .row + .row {
    margin-top: 22px;
  }

  .row {
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 16px !important;
    }
  }

  .line-buttom {
    width: 100%;
    border-bottom: 1px solid #bfbfbf;
    padding: 5px 0px;
    margin: 14px 0;
  }

  .img {
    width: 140px;
    height: auto;
  }

  .section + .section {
    margin-top: 30px;
  }

  .section {
    margin: 10px;
  }

  .h1 {
    width: 100%;
    font-size: 24px;
    color: #00b6fe;
  }

  .h3 {
    width: 100%;
    font-size: 18px;
  }

  .form + .form,
  .form-image + .form,
  .form-check + .form,
  .form + .form-check {
    margin-top: 32px;
  }

  .form {
    display: flex;
    align-items: center;
    min-height: 50px;
    height: auto;
    background-color: #efefef;
    border: 1px solid #d4d4d4;
    padding: 14px;
    border-radius: 4px;
    position: relative;

    &-image {
      height: auto !important;
      border: 1px solid #d4d4d4;
      padding: 14px;
      border-radius: 4px;

      .image-list {
        height: 150px;
        img {
          width: auto;
          max-width: 150px;
          height: auto;
          max-height: 150px;
        }
      }
    }

    &-check {
      display: flex;
      flex-direction: column;

      .list {
        display: grid;
        grid-template-rows: auto;
        gap: 14px;
      }
    }

    .label {
      font-size: 14px;
      color: #858585;
      position: absolute;
      top: -12px;
      z-index: 1;

      &::after {
        content: ' ';
        display: block;
        position: absolute;
        background: white;
        height: 2px;
        top: 46%;
        left: -0.2em;
        right: -0.2em;
        z-index: -1;
      }
    }

    .value {
      font-size: 16px;
      color: #000;
      margin: 0;
    }
  }
}
