@import 'variables';
@import 'custom';
@import 'antd-custom';
@import 'pdf';

@import '~react-image-gallery/styles/scss/image-gallery.scss';

body {
  font-family: 'Prompt', 'Arial', sans-serif !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 40px !important;
  stroke: $primary-color !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 400px !important;
  height: 100%;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  height: 60px !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  max-height: 150px;
  height: 100%;
  max-width: 92px !important;
  width: auto;
}

.image-gallery-thumbnail-inner {
  max-width: 92px !important;
}

.gray-color {
  color: $gray-color;
}

.mb-20 {
  margin-bottom: 20px !important;
}
