#root {
  height: 100%;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: $primary-color;
}

.logo {
  height: 42px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  padding: 15px;
  margin: 10px;
  transition: all 0.3s ease-in-out;
}

.site-layout .site-layout-background {
  background: #fff;
}

.logo {
  img {
    width: 100%;
  }
}

.card-login {
  .login-logo {
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}

.card-status {
  background-color: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-base;
  .title {
    height: 22px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
  }
  .count {
    text-align: end;
    height: 38px;
    margin: 5px 5px 10px 5px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-size: 30px;
    line-height: 38px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}

.card-report + .card-report {
  margin-top: 10px !important;
}

.card-report {
  background-color: white;
  padding: 20px;
  height: 250px;
  width: 100%;
  border-radius: $border-radius-base;
  text-align: center;
  .title {
    height: 22px;
    color: #000;
    font-size: 14px;
    line-height: 22px;
  }
}

.user-avatar {
  background-color: $primary-color !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.range-item {
  text-align: end;
  margin-bottom: 20px;
}

.dashboard {
  display: flex;
  flex-direction: column;

  .report {
    width: 100%;
  }

  .status {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-bottom: 20px;

    @media (max-width: $container) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.btn-add-dialog {
  padding: 40px;
  border: 1px dashed #d9d9d9;
  border-radius: $border-radius-base;
  text-align: center;
  background: #fff;
  margin: 10px 0;
  &:hover {
    border: 1px dashed $primary-color;
  }
}
.ant-avatar {
  background: $primary-color;
}

.mr-10 {
  margin-right: 10px;
}

.btn-add-respone {
  text-align: center;
  margin: 5px;
}

.dialog {
  margin-top: 10px;
  .loading {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
  .header {
    background-color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-radius: $border-radius-base $border-radius-base 0 0;
    border-bottom: 1px $smoke-color solid;
    color: black;
  }
  .dialog-item {
    border-bottom: 1px solid #f0f0f0;
    border-radius: $border-radius-base;
    .list {
      display: flex;
      background-color: #fff;

      @media (max-width: 768px) {
        flex-direction: column !important;
      }

      .btn-action {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 10px;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: row;
          justify-content: center;
        }
        span {
          font-size: 18px;
          :hover {
            fill: $primary-color;
          }
        }
      }

      .item {
        padding: 10px;
      }

      .content {
        width: 100%;
        padding: 10px 20px;
        background-color: #dceeff;
        border-radius: $border-radius-base;
        @media (max-width: 768px) {
          max-width: 100% !important;
        }
        .entity {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 15px 0;
          .item {
            margin: 5px 0;
            background-color: #fff;
            padding: 5px 15px;
            border-radius: $border-radius-base + 10;
            color: #000;
            p {
              margin: 0 !important;
            }
          }
        }
        .respone {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin: 15px 0;
          .video-message {
            max-height: 200px;
            max-width: 300px;
            height: 100%;
            width: 100%;
            border-radius: 10px;
            &:focus {
              outline: none;
            }
          }
          img {
            border-radius: $border-radius-base + 10;
          }
          .item {
            margin: 5px 0;
            background-color: #00c300;
            padding: 5px 15px;
            border-radius: $border-radius-base + 10;
            color: #000;
          }
        }
      }
    }
  }
}

.dialog-list + .dialog-list {
  margin-top: 10px;
}

.text-center {
  width: 100%;
  text-align: center !important;
}

.custom-input {
  display: flex;
  align-items: center;
}

.custom-input + .custom-input,
.ant-tag + .ant-tag {
  margin-top: 10px;
}

.custom-tag {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  font-size: 14px;
  height: auto;
  margin: 5px;
  padding: 5px 10px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: $border-radius-base + 10;
  cursor: default;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.role {
  text-transform: uppercase;
}

.line-avatar {
  border-radius: 50%;
  width: 40px;
}

.out-scope {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  color: black;
  border-radius: 11px;
}

.out-scope + .out-scope {
  margin-top: 10px;
}

.pagination {
  margin: 20px 0px;
  text-align: center;
  width: 100%;
}

.recharts-legend-wrapper {
  display: flex;
  justify-content: center;
}

.uppercase {
  text-transform: uppercase;
}

.adv-detail,
.product-detail {
  .title {
    margin-top: 20px;
    padding: 5px;
    color: black;
    margin-bottom: 0px;
    background-color: $smoke-color;
  }
  .title:nth-child(1) {
    margin-top: 0px;
  }

  .label {
    margin-top: 10px;
    color: black;
    margin-bottom: 0px;
  }

  .detail-content {
    padding: 10px;
  }
}

.image-gallery-thumbnail {
  border: 2px solid #fff !important;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: 2px solid $primary-color !important;
}

.haeder-username {
  font-size: 14px;
}
